<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="user-card" style="padding-top: 10px;">
        <div class="row">
          <div class="col-sm-12">
            <button @click="bulkReview" class="btn btn-primary">Bulk Review</button>
          </div>
        </div>
        <div class="row" style="margin-bottom: 20px;" v-for="item in users" :key="item.id">
              <div class="col-sm-4">
                <router-link :to="`/roomie/user/${item.user.id}`">
                  <img class="user-photo" :src="photo(item.user)"/>
                  <p>{{item.user.first_name}} {{item.user.last_name}}</p>
                  <br>
                </router-link>
              </div>
              <div class="col-sm-4">
                  <img class="user-photo" :src="item.photoUrl"/>
                  <br><br>
                  <button style="margin-left: 10px;" @click="review(item.id)" class="btn btn-primary">Review</button>
              </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'

export default {
  name: "FlaggedProfilePictures",
  components: {
  },
  data() {
    return {
      suspendConfirm: false,
      rejectId: null
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.dispatch(StoreActions.getRecent);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    photo(user) {
      if(user.photos.length > 0) {
        return user.photos[0].url;
      }

      return null;
    },
    review(id) {
      this.dispatch(StoreActions.markReviewed, id);
    },
    bulkReview() {
      let ids = this.users.map((it) => {
        return it.id
      });
      this.dispatch(StoreActions.bulkReview, ids);
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.users
    ])
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
.btn-primary {
  background: #46c3f0 !important;
  border-color: #46c3f0 !important;
}

a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width:200px;
  height:200px;
  border-radius: 10px;
  object-fit: cover;
}

@media (max-width:767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>